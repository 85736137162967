import axios from 'axios'
import type {
  RespError,
} from './helpers'
import {
  http_delete,
  http_get,
  http_post,
  http_put,
  renderUrlTemplate,
} from './helpers'
import { version } from './config'
import type {
  AddClassMemberQuery,
  AddOrUpdateClassQuery,
  ClassQuery,
  Member,
  MemberQuery,
  RemoveClassMemberQuery,
  SetClassManagerQuery,
} from '@/features/collage/types'

export const getClassList = http_post<
  ClassQuery,
  {
    totalCount: number
    items: {
      name: string
      description: string
      creationTime: string
      id: string
    }[]
  }
>(`/api/v${version}/a/Study/Classes_PageList`)

export const getMemberList = http_post<
  MemberQuery,
  {
    totalCount: number
    items: Member[]
  }
>(`/api/v${version}/a/Study/Users_PageByStudy`)

export const addClassMember = http_post<Partial<AddClassMemberQuery>, any>(
  `/api/v${version}/a/Study/BatchAddClassMember`,
)

export const addOrUpdateClass = http_post<Partial<AddOrUpdateClassQuery>, any>(
  `/api/v${version}/a/Study/Classes_Save`,
)

export const removeClassMember = http_post<Partial<RemoveClassMemberQuery>, any>(
  `/api/v${version}/a/Study/BatchRemoveClassMember`,
)

export const setClassManager = http_post<Partial<SetClassManagerQuery>, any>(
  `/api/v${version}/a/Study/SetClassManager`,
)

export const deleteClass = http_post<{ id: string }, any>(
  `/api/v${version}/a/Study/Classes_Remove`,
)

export const downloadExcelTemplate = http_post<void, any>(
  `/api/v${version}/m/UploadFiles/GenerateImportMemberUserAccountsDtoTemplateAsync`,
)
export const importStudent = http_post<{ fileName: string; classId?: string }, any>(
  `/api/v${version}/m/UploadFiles/ImportUserFile`,
)

function my_http_post<Opt, Res>(url: string) {
  return async (opt: Opt) => {
    const newUrl = renderUrlTemplate(url, opt)
    const res: unknown = await axios.post(newUrl, opt, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res as { data: Res } | RespError
  }
}
export const uploadExcelTemplate = my_http_post<any, void>(
  `/api/v${version}/m/UploadFiles/UploadExcelFileAsync`,
)

export const exportStudent = http_post<
  MemberQuery,
  {
    hash: string
    fileName: string
    fileType: string
  }
>(`/api/v${version}/a/Study/ExportUserDtoFile_PageByStudy`)
