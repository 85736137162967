// 表单校验规则
export type Rule = Record<string, { required: boolean; message: string; trigger: string }[]>

// 班级
export interface Class {
  name: string
  creationTime: string
  id: string
}

// 班级查询参数
export interface ClassQuery {
  name?: string
  sorting?: '0' | '1'
  skipCount?: number
  maxResultCount?: number
}

// 成员查询参数
export interface MemberQuery {
  search?: string
  skipCount: number
  maxResultCount: number
  inClass?: boolean
  sorting?: '0' | '1'
  classId?: string
  userTypeArr?: UserType[]
  taskId?: string
  inTask?: boolean
  /**
   * 账号
   */
  nickName?: string
  /**
   * 手机号
   */
  phoneNumber?: string
  /**
   * 姓名
   */
  userName?: string
  /**
   * 启用状态
   */
  isEnabled?: boolean
}

// 将成员添加到班级参数
export interface AddClassMemberQuery {
  classId: string
  userType: UserType
  userIds: string[]
}
// 新增或修改班级参数
export interface AddOrUpdateClassQuery {
  id: string
  name: string
}

// 移除班级成员参数
export interface RemoveClassMemberQuery {
  classId: string
  userType: UserType
  userIds: string[]
}

// 设置班级班主任参数
export interface SetClassManagerQuery {
  classId: string
  userId: string
}

// 翻页
export interface Pagination {
  pageSize: number
  currentPage: number
}

// 成员
export interface Member {
  /**
   * 姓名
   */
  nickName: string
  /**
   * 账号
   */
  userName: string
  phoneNumber: string
  userId: string
  isManager?: boolean
  createdAt?: string
  isEnabled?: boolean
  userTypeArr: UserType[]
}

// 成员表格表头
export interface MemberTableHeader {
  description: string
  key: keyof Member
}

// 成员类型
export enum UserType {
  student = 1,
  teacher = 2,
}

// 各表格表头
export interface TableHeader {
  description: string
  key: string
}

// 成员角色
export interface Role {
  display_name: string
  key: UserType
}

// 班级管理Data classes/index.vue
export interface CollageData {
  classModalVisible: boolean
  total: number
  selectedClass?: Class
  currentEditClass?: Class
  classesLoading: boolean
  classQuery: ClassQuery
  classesList: Class[]
  memberLoading: boolean
  memberQuery: MemberQuery
  pagination: Pagination
  memberList: Member[]
  selectedMembers: Member[]
  addStudentModalVisible: boolean
  addStudentModalInputText: string
  addStudentModalSelectValue: number | undefined
  addStudentModalList: Member[]
  addStudentModalTotal: number
  addStudentModalCurrentPage: number
  addStudentModalLoading: boolean
  addStudentTableSelectList: Member[]
}

export interface TaskTypes_PageListQuery {
  parentId?: string
  name?: string
  sorting?: string
  skipCount: number
  maxResultCount: number
}
export interface TaskTypes_SaveQuery {
  name: string
  parentId?: string
  creationTime?: string
  createUserId?: string
  id?: string
}

export interface TaskTypes_RemoveQuery {
  id: string
}
export interface Tasks_PageListQuery {
  typeId?: string
  name?: string
  sorting?: '0' | '1'
  // TODO 作业参数 状态 进行中。。。
  status?: number
  skipCount: number
  maxResultCount: number
}
export interface Tasks_SaveQuery {
  name: string
  typeId?: string
  createUserId?: string
  creationTime?: string
  beginTime?: string
  beginTimeStr?: string
  endTime?: string
  endTimeStr?: string
  logo?: string
  remark?: string
  targetType?: string
  id?: string
}
export interface TaskType {
  name: string
  parentId?: string
  creationTime?: string
  createUserId?: string
  id: string
}
export interface Task {
  name: string
  typeId: string
  createUserId?: string
  creationTime?: string
  beginTime: string
  endTime: string
  logo?: string
  remark?: string
  targetType?: number
  id: string
}

export interface TaskFile {
  taskFile: {
    uploadFileId: string
    taskId: string
    isTaskRule: boolean
    taskRuleId: string
    creationTime: string
    createUserId: string
    id: string
  }
  uploadFile: {
    fileId: string
    fileName: string
    fileExtention: string
    fileTaskId: string
    fileConvertState: number
    fileHash: string
    fileSize: number
    fileUrl: string
    modelGroupId: string
    type: number
    isLocked: boolean
    creationTime: string
    createUserId: string
    id: string
  }
}

export interface TaskFilesQuery {
  taskId: string
  isTaskRule?: boolean
  sorting?: '0' | '1'
  skipCount?: number
  maxResultCount?: number
}
export interface TaskFilesExtendsQuery {
  taskFile: Partial<TaskPureFile>
  uploadFile: Partial<UploadFile>
}
export interface TaskPureFile {
  isTaskRule: boolean
  taskRuleId: string
  uploadFileId: string
  taskCommitId: string
  taskId: string
  creationTime: string
  createUserId: string
  id: string
}
export interface UploadFile {
  fileId: string
  fileName: string
  fileExtention: string
  fileTaskId: string
  fileConvertState: number
  fileHash: string
  fileSize: number
  fileUrl: string
  modelGroupId: string
  type: number
  isLocked: true
  creationTime: string
  createUserId: string
  id: string
}
export interface CheckReportInfo {
  taskId: string
  taskCommitId: string
  mark: number
  isQualified: boolean
  reportUrl: string
  path: string
  hash: string
  checkResult: string
  errorFileUrl: string
  errorFilePath: string
  errorFileHash: string
  errorMessage: string
  createUserId: string
  creationTime: string
  id: string
}

export interface CommitFile {
  isTaskRule: boolean
  taskRuleId: string
  uploadFileId: string
  taskCommitId: string
  taskId: string
  creationTime: string
  createUserId: string
  id: string
}

export interface CommitInfo {
  taskName: string
  className: string
  userName: string
  nickName: string
  taskId: string
  classId: string
  userId: string
  status: number
  creationTime: string
  createUserId: string
  id: string
  mark: number
  remark: string
}

export interface OpinionInfo {
  mark: number
  status: number
  remark: string
  taskCommitId: string
  taskId: string
  imageUrl: string
  creationTime: string
  createUserId: string
  id: string
}

export interface Commit {
  userId: string
  userName: string
  phoneNumber: string
  nickName: string
  commitInfo: CommitInfo
  commitFile: CommitFile
  uploadFile: UploadFile
  opinionInfo: OpinionInfo
  checkReportInfo: CheckReportInfo
}

export interface AllRules {
  checkRule: {
    delayDays: number
    name: string
    rulesContent: string
    typeContent: string
    taskId: string
    isSystem: boolean
    creationTime: string
    createUserId: string
    id: string
  }
  checkRuleConfig: {
    propertiesPoint: number
    attributesPoint: number
    propertieStandard: number
    taskId: string
    id: string
  }
  checkRuleProperties: {
    difference: number
    mark: number
    taskId: string
    id: string
  }[]
}

export interface ScoringRubric {
  name: string
  score: number
  isChecked: boolean
  matchKeys: string
}
export interface Attributes {
  name: string
  type: number
  percent: number
  values: string[]
  middleValue?: number
  precesion?: number
  candidates?: string[]
  sample?: string
}

export interface ComponentRule {
  baseType?: string
  componentType: string
  componentName?: string
  score: number
  attributes: Attributes[]
  isHidden?: boolean
}
export interface NumAttrList {
  Name: string
  Values: number[]
  Precesion: number
  percent: number
}

export interface Rules {
  ElementNames: string[]
  score: number
  TextAttrList: TextAttrList[]
  NumAttrList: NumAttrList[]
  TotalMatch: number
}

export interface TextAttrList {
  Name: string
  Values: string[]
  percent: number
}
export enum ComponentTypeEnum {
  any,
  number,
  text,
}
export const ComponentType = {
  [ComponentTypeEnum.any]: '任意值',
  [ComponentTypeEnum.number]: '数值',
  [ComponentTypeEnum.text]: '文本',
}
export interface FileData {
  name: string
  percentage: number
  raw: File
  size: number
  status: string
  uid: number
  fileId?: string
  filePath?: string
  error?: boolean
}
export enum PublicStatus {
  all,
  public,
  unPublic,
}
export enum CommitStatus {
  // 保存 = 0, 提交 = 1, 驳回 = -1, 通过 = 2
  all = 3,
  save = 0,
  committed = 1,
  reject = -1,
  pass = 2,
}
export enum ConverStatus {
  unStart,
  inProgress,
  success,
  fail,
}
export interface ProcessedFile {
  id?: string
  fileName?: string
  uploadFileId?: string
  fileExtention?: string
  creationTime?: string
  fileSize?: number
  isPublic?: boolean
  fileConvertState?: number
}

export interface CommitTableData {
  userName: string // 名称
  userId: string // 学号
  className: string // 班级
  creationTime: string // 提交时间
  status: number // 状态
  systemGrade: number // 系统审查得分
  grade: number // 得分
  commitId: string // commit id
}

export interface NoticeItem {
  taskId: number
  content: string
  creationTime: string
  createUserId: number
  id: number
}
