
import Vue, { PropType } from "vue";
import { memberTableHeader } from "@/features/collage/const";
import { ElTable } from "element-ui/types/table";
import { Member } from "../types";
import moment from "moment";
export default Vue.extend({
  name: "MemberList",
  props: {
    loading: { type: Boolean as PropType<boolean> },
    listData: { type: Array as PropType<Member[]>, default: [] },
    total: { type: Number as PropType<number>, default: 0 },
    currentPage: { type: Number as PropType<number>, default: 1 },
    pageSize: { type: Number as PropType<number>, default: 10 }
  },
  data() {
    return {
      memberTableHeader
    };
  },
  computed: {},
  filters: {
    formatTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {
    handleMemberSelectionChange(v: Member[]) {
      this.$emit("onSelect", v);
    },
    handleRowClick(row: Member) {
      (this.$refs.table as ElTable).toggleRowSelection(row);
    },
    handlePageSizeChange(v: number) {
      this.$emit("update:pageSize", v);
    },
    handleCurrentPageChange(v: number) {
      this.$emit("update:currentPage", v);
    }
  }
});
