
import Ellipsis from '@/components/Ellipsis.vue';
import Vue, { PropType } from 'vue';
import { Class } from '../types';

export default Vue.extend({
  components: { Ellipsis },
  name: 'classList',
  props: {
    classesList: { type: Array as PropType<Class[]>, default: [] },
  },
  data() {
    return {
      selectedClass: {},
    };
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters.isAdmin;
    },
    canEdit(): boolean {
      return !!this.$route.query.edit;
    },
  },
  methods: {
    handleRowClick(data) {
      this.selectedClass = data;
      this.$emit('onRowClick', data.id);
    },
    handleEditClick(data) {
      this.$emit('onEditClick', data);
    },
    handleDeleteClick(data) {
      this.$emit('onDeleteClick', data);
    },
  },
});
