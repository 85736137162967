import type {
  Class,
  Commit,
  Member,
  MemberTableHeader,
  Role,
  Task,
  TaskFile,
  TaskType,
} from '@/features/collage/types'
import {
  UserType,
} from '@/features/collage/types'

export const rolesMap: Role[] = [
  { key: 1, display_name: '学生' },
  { key: 2, display_name: '教师' },
  { key: 0, display_name: '其他' },
]

export const mockClassList: Class[] = new Array(10).fill(1).map((v, index) => ({
  name: `数字媒体技术${index}班`,
  creationTime: String(new Date()),
  id: `${index}`,
  nickName: '段吉鹏',
}))

export const memberTableHeader: MemberTableHeader[] = [
  // { description: "角色", key: "userTypeArr" },
  { description: '账号', key: 'userName' },
  { description: '电话', key: 'phoneNumber' },
  { description: '姓名', key: 'nickName' },
  { description: '新增时间', key: 'createdAt' },
  { description: '启用状态', key: 'isEnabled' },
]

export const mockMemberList: Member[] = new Array(9).fill(1).map((v, index) => ({
  nickName: `学生${index}`,
  userName: `账号${index}`,
  phoneNumber: '133xxxxxxxx',
  userId: `${index}`,
  userTypeArr: [0],
}))

// tag标签的类型 学生为tag组件的success类型
export const TagType = {
  [UserType.student]: 'success',
  [UserType.teacher]: '',
}

export const mockTaskTypesList: TaskType[] = new Array(10).fill(1).map((v, index) => ({
  id: `${index}`,
  name: `test${index}`,
}))
export const mockTaskList: Task[] = new Array(10).fill(1).map((v, index) => ({
  id: `${index}`,
  name: `测试作业${index}`,
  typeId: `${index}`,
  createUserId: `创建者${index}`,
  creationTime: `创建时间${index}`,
  beginTime: `${1638374400000}`,
  endTime: `${1639756800000}`,
  logo: `${index}`,
  remark: `${index}`,
  targetType: index,
}))

export const mockTaskFiles: TaskFile[] = []
export const extensions = [
  '.rvt',
  '.stl',
  '.obj',
  '.rfa',
  '.fbx',
  '.rte',
  '.rft',
  '.dgn',
  '.sat',
  '.skp',
  '.3ds',
  '.3mf',
  '.dae',
  '.gim',
  '.ifc',
  '.dxf',
  '.dwg',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.txt',
]
export const mockCommits: Commit[] = new Array(10).fill({
  userId: '1',
  userName: 'string',
  phoneNumber: 'string',
  nickName: 'string',
  commitInfo: {
    taskName: 'string',
    className: 'string',
    userName: 'string',
    nickName: 'string',
    taskId: '1',
    classId: '1',
    userId: '1',
    status: 0,
    creationTime: '2021-11-24T08:49:33.211Z',
    createUserId: '1',
    id: '1',
  },
  commitFile: {
    uploadFileId: '385308842101047297',
    taskId: '385238018794979328',
    isTaskRule: false,
    taskRuleId: 0,
    creationTime: '2021-11-29T13:59:46.651925',
    createUserId: 0,
    id: '385308842109435905',
  },
  uploadFile: {
    fileId: 'rpvPH0E4yU-xA3SAEG4vAA==',
    fileName: 'ǽH.rvt',
    fileExtention: '.rvt',
    fileTaskId: '385238018794979328',
    fileConvertState: 2,
    fileHash: null,
    fileSize: 5529600,
    fileUrl: 'Boron/DownloadFile/Original/rpvPH0E4yU-xA3SAEG4vAA==',
    modelGroupId: '00000000-0000-0000-0000-000000000000',
    type: 0,
    isLocked: false,
    creationTime: '2021-11-29T13:59:46.649694',
    createUserId: 0,
    id: '385308842101047297',
  },
  opinionInfo: {
    mark: 0,
    status: 0,
    remark: 'string',
    taskCommitId: '1',
    taskId: '1',
    imageUrl: 'string',
    creationTime: '2021-11-24T08:49:33.211Z',
    createUserId: '1',
    id: '1',
  },
  checkReportInfo: {
    taskId: '1',
    taskCommitId: '1',
    mark: 0,
    isQualified: true,
    reportUrl: 'string',
    path: 'string',
    hash: 'string',
    checkResult: 'string',
    errorFileUrl: 'string',
    errorFilePath: 'string',
    errorFileHash: 'string',
    errorMessage: 'string',
    createUserId: '0',
    creationTime: '2021-11-24T08:49:33.211Z',
    id: '1',
  },
})
