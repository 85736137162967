
import Vue from 'vue';
import * as api_Classes from '@/api/Classes';
import * as api from '@/api';
import MemberFilter from '@/features/collage/memberFilter/index.vue';
import ClassList from '@/features/collage/classList/index.vue';
import ClassModal from '@/features/collage/classModal/index.vue';
import MemberList from '@/features/collage/memberList/index.vue';
import { mockClassList, mockMemberList } from '@/features/collage/const';
import {
  Class, CollageData, Member, Pagination,
} from '@/features/collage/types';
import { myConfirm } from '@/utils/confirm';
import { ibuidingAPI } from '@/api/ibuidingAPI';

export default Vue.extend({
  name: 'Classes',
  components: {
    MemberFilter,
    ClassList,
    ClassModal,
    MemberList,
  },
  data(): CollageData {
    return {
      total: 0,
      selectedClass: undefined,
      currentEditClass: undefined,
      classModalVisible: false,
      classesLoading: false,
      classQuery: {
        name: undefined,
        skipCount: 0,
        maxResultCount: 300,
      },
      classesList: [],
      memberLoading: false,
      memberQuery: {
        search: undefined,
        skipCount: 0,
        maxResultCount: 999,
        inClass: undefined,
        sorting: undefined,
        classId: undefined,
      },
      pagination: {
        pageSize: 10,
        currentPage: 1,
      },
      memberList: [],
      selectedMembers: [],
      addStudentModalVisible: false,
      addStudentModalInputText: '',
      addStudentModalSelectValue: undefined,
      addStudentModalList: [],
      addStudentModalTotal: 0,
      addStudentModalCurrentPage: 1,
      addStudentModalLoading: false,
      addStudentTableSelectList: [],
    };
  },
  computed: {
    addStudentModalQuery(): any {
      return {
        skipCount: (this.addStudentModalCurrentPage - 1) * 10,
        maxResultCount: 10,
        inClass: false,
        classId: this.selectedClass?.id,
        search: this.addStudentModalInputText,
        userTypeArr: [1],
      };
    },
    isAdmin(): boolean {
      return this.$store.getters.isAdmin;
    },
    canEdit(): boolean {
      return !!this.$route.query.edit;
    },
    sortedClassList(): any[] {
      return this.sortList(this.classesList);
    },
  },
  watch: {
    pagination: {
      handler(pagination: Pagination) {
        this.memberQuery.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.memberQuery.maxResultCount = pagination.pageSize;
      },
      deep: true,
    },
    memberQuery: {
      handler() {
        this.fetchMemberList();
      },
      deep: true,
    },
    addStudentModalQuery: {
      handler(val, oldVal) {
        if (val.skipCount === oldVal.skipCount && val.skipCount !== 0) {
          this.addStudentModalCurrentPage = 1;
        } else {
          this.fetchAddStudentModalList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '班级管理',
        path: 'classes',
      },
    ]);
    this.fetchClassList();
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
    selectEnable(row) {
      if (this.memberList.some((v) => v.userId === row.userId)) {
        return false;
      }
      return true;
    },
    handleEditClick(data: Class) {
      this.currentEditClass = data;
      this.classModalVisible = true;
    },
    async handleClassDeleteClick(data: Class) {
      myConfirm(this, {
        text: `是否要删除${data.name}`,
        resolve: async () => {
          const res = await api_Classes.deleteClass({ id: data.id });
          if (res && !api.error(res)) {
            this.fetchClassList();
            this.selectedClass = undefined;
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          } else {
            this.$message.error(res.error?.message);
          }
        },
      });
    },
    handleCreateClick() {
      this.currentEditClass = undefined;
      this.classModalVisible = true;
    },
    handleConfirm() {
      this.$message.success('新建班级成功');
      this.classModalVisible = false;
      this.fetchClassList();
    },
    handleEditConfirm({ id: classId, name }) {
      this.$message.success('编辑班级成功');
      this.classModalVisible = false;
      this.classesList = this.classesList.map((v) => {
        if (v.id === classId) {
          return { ...v, name };
        }
        return v;
      });
      if (this.selectedClass) {
        this.selectedClass = { ...this.selectedClass, name };
      }
    },
    handleRowClick(classId: string) {
      this.pagination.currentPage = 1;
      this.memberQuery = { ...this.memberQuery, classId, inClass: true };
      this.selectedClass = this.classesList.find(({ id }) => id === classId);
    },
    async fetchMemberList() {
      this.memberLoading = true;
      const res = await api_Classes.getMemberList(this.memberQuery);
      if (res && !api.error(res)) {
        this.memberList = (res.data.items as any) || [];
        this.total = res.data.totalCount;
      } else {
        this.$message.error(res?.error?.message);
      }
      this.memberLoading = false;
    },
    async fetchClassList() {
      this.classesLoading = true;
      const res = await api_Classes.getClassList(this.classQuery);
      this.classesLoading = false;
      if (res && !api.error(res)) {
        this.classesList = res.data?.items.reverse() || [];
      } else {
        this.$message.error(res?.error?.message);
      }
    },
    async handleDeleteClick(members: Member[]) {
      myConfirm(this, {
        text: '确认移除此学生？',
        resolve: async () => {
          const res = await api_Classes.removeClassMember({
            classId: this.selectedClass?.id,
            userIds: members.map(({ userId }) => userId),
          });
          if (res && !api.error(res)) {
            this.$message.success('移出成功');
            this.fetchMemberList();
          }
        },
      });
    },
    async handleExcelImport(_file) {
      this.memberLoading = true;
      const file = _file.raw;
      const formData = new FormData();
      formData.append('file', file);
      const res: any = await api_Classes.uploadExcelTemplate(formData);
      if (res && !api.error(res)) {
        const fileName: string = res.data;
        const res2 = await api_Classes.importStudent({
          fileName,
          classId: this.selectedClass?.id,
        });
        if (res2 && !api.error(res2)) {
          this.$message.success('批量导入学生成功');
          this.fetchMemberList();
        } else {
          this.$message.error(res2?.error?.message);
          this.memberLoading = false;
        }
      } else {
        this.$message.error(res?.error?.message);
        this.memberLoading = false;
      }
    },
    async fetchAddStudentModalList() {
      this.addStudentModalLoading = true;
      const res = await api_Classes.getMemberList(this.addStudentModalQuery);
      if (res && !api.error(res)) {
        this.addStudentModalList = res.data.items;
        this.addStudentModalTotal = res.data.totalCount;
        this.addStudentModalLoading = false;
      }
    },
    async handleAddStudentClick() {
      this.addStudentModalInputText = '';
      this.fetchAddStudentModalList();
      this.addStudentModalVisible = true;
    },
    handleAddStudentModalPageChange(e) {
      this.addStudentModalCurrentPage = e;
    },
    handleAddStudentTableSelectChange(e) {
      this.addStudentTableSelectList = e;
    },
    async handleAddStudentModalConfirm() {
      if (!this.addStudentTableSelectList.length) {
        this.$message.warning('请先选择学生！');
        return;
      }
      const res = await api_Classes.addClassMember({
        classId: this.selectedClass?.id,
        userIds: this.addStudentTableSelectList.map((v) => v.userId),
      });
      if (res && !api.error(res)) {
        this.$message.success('添加学生成功！');
        this.addStudentModalVisible = false;
        this.fetchMemberList();
      } else {
        this.$message.error(res?.error?.message);
      }
    },
  },
});
