
import Vue from "vue";
enum EnableStatus {
  all,
  enable,
  disable
}
const initialParams = {
  nickName: "",
  phoneNumber: "",
  userName: "",
  isEnabled: 0 as EnableStatus | undefined
};

export default Vue.extend({
  name: "MemberFilter",
  data() {
    return {
      queryParams: { ...initialParams }
    };
  },
  methods: {
    reset() {
      this.queryParams = { ...initialParams };
      const isEnabled =
        this.queryParams.isEnabled === undefined
          ? undefined
          : this.queryParams.isEnabled === EnableStatus.all
          ? undefined
          : this.queryParams.isEnabled === EnableStatus.enable
          ? true
          : false;
      this.$emit("onChangeMemberQuery", { ...this.queryParams, isEnabled });
    },
    submit() {
      const isEnabled =
        this.queryParams.isEnabled === undefined
          ? undefined
          : this.queryParams.isEnabled === EnableStatus.all
          ? undefined
          : this.queryParams.isEnabled === EnableStatus.enable
          ? true
          : false;
      this.$emit("onChangeMemberQuery", { ...this.queryParams, isEnabled });
    }
  }
});
