
import Vue, { PropType } from 'vue';
import * as api_Classes from '@/api/Classes';
import * as api from '@/api';
import { ElForm } from 'element-ui/types/form';
import { Class, Rule } from '../types';

interface IData {
  classRules: Rule;
  formData: { name: string };
}
export default Vue.extend({
  name: 'classModal',
  props: {
    visible: { type: Boolean as PropType<boolean> },
    selectedClass: { type: Object as PropType<Class> },
  },
  data(): IData {
    return {
      classRules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
      formData: {
        name: this.selectedClass?.name || '',
      },
    };
  },
  computed: {
    isEditMode(): boolean {
      return !!this.selectedClass;
    },
  },
  watch: {
    selectedClass(val: Class) {
      this.formData.name = val?.name || '';
    },
  },
  methods: {
    handleClose() {
      this.formData.name = this.selectedClass?.name || '';
      this.$emit('update:visible', false);
    },
    classCreateSubmit() {
      // 保存提交
      (this.$refs.classForm as ElForm).validate(async (valid) => {
        if (valid) {
          const res = await api_Classes.addOrUpdateClass({
            name: this.formData.name,
            id: this.isEditMode ? this.selectedClass.id : undefined,
          });
          if (res && !api.error(res)) {
            this.isEditMode
              ? this.$emit('onEditConfirm', { id: this.selectedClass.id, name: this.formData.name })
              : this.$emit('onConfirm');
          } else {
            this.$message.error(res?.error?.message);
          }
        }
      });
    },
  },
});
